<template lang="pug">
.notification-block
  .header Рассылка по шаблонам
  .content
    ui-checkbox(
      id="notifications"
      label="Получать рассылку на email"
      :model-value="enableNotifications"
      @update:modelValue="$emit('update:enableNotifications', $event)"
    )
    ui-checkbox(
      id="distributions"
      label="Автоматическая рассылка тендеров по новым шаблонам на email"
      :model-value="autoCreateTemplateNotification"
      @update:modelValue="$emit('update:autoCreateTemplateNotification', $event)"
    )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

export default defineComponent({
  name: "EmailNotificationBlock",
  components: {
    UiCheckbox,
  },
  emits: [
    "update:enableNotifications",
    "update:autoCreateTemplateNotification",
  ],
  props: {
    enableNotifications: {},
    autoCreateTemplateNotification: {},
  },
})
</script>

<style scoped lang="scss">
.notification-block {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .header {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
}
</style>
