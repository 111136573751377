<template lang="pug">
.notification-block
  .header Email для уведомлений
  .content
    validation-row(
      not-empty
      type-email
      watch-validation
      required
      ref="refEmailInput"
      label="Email для уведомлений"
      placeholder="Введите email"
      :model-value="modelValue"
      @error="$emit('validate:error', 'form_email')"
      @correct="$emit('validate:correct', 'form_email')"
      @update:modelValue="$emit('update:modelValue', $event)"
    )
    ui-checkbox(v-model="employBasicEmailVal" id="use_contact_email" label="Использовать контактный email")
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs } from "vue";

import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import type { PropType } from "vue";
import type { ManagerI } from "@/stores/auth/UserInterface";

export default defineComponent({
  name: "EmailBlock",
  components: {
    UiCheckbox,
    ValidationRow,
  },
  emits: [
    'validate:correct',
    'validate:error',
    'update:modelValue',
  ],
  props: {
    modelValue: {},
    user: {
      type: Object as PropType<ManagerI>,
      default: () => ({})
    },
  },
  setup(props, context) {

    const refEmailInput = ref();
    const { user } = toRefs(props);

    const employBasicEmailVal = computed({
      get(): boolean {
        return props.modelValue === user.value?.login
      },
      set(value: boolean): void {
        context.emit('update:modelValue', value ? user.value?.login : '')
      },
    })

    function validate() {
      refEmailInput.value.validate();
    }

    return  {
      refEmailInput,
      employBasicEmailVal,
      validate,                // для внешней компоненты
    }
  }
})
</script>

<style scoped lang="scss">
.notification-block {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .header {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 332px;
    gap: 8px;
  }
}
</style>
