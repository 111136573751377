import { ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { useHasChanges } from "@/use/other/useHasChanges";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";

import type { TemplateI } from "~/stores/templates/SearchTemplatesInterface";
import type { UserNotificationsI } from "@/stores/auth/UserInterface";
import useTemplatesStore from "~/stores/templates/useTemplatesStore";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";

function getDefaultApplication() {
  return [
    { isEnabled: false, time: '09:00', hours: 24 },
    { isEnabled: false, hours: 1 },
  ]
}

function getDefaultAuction() {
  return [
    { isEnabled: false, hours: 1 },
    { isEnabled: false, minutes: 10 },
  ]
}

function getDefaultContract() {
  return [
    { isEnabled: false, time: '09:00', hours: 24 },
    { isEnabled: false, time: '09:00' },
  ]
}

function getDefaultNotifications(): UserNotificationsI {
  return {
    emailAccount: {
      email: '',
      enableNotifications: false,
      autoCreateTemplateNotification: false,
    },
    phoneAccount: {
      settings: {
        application: getDefaultApplication(),
        auction: getDefaultAuction(),
        contract: getDefaultContract(),
      }
    }
  }
}

export function useNotificationsSettings(id?: number) {

  const isFetching = ref(false);
  const isSaving = ref(false);
  const error = ref('');

  const templatesStore = useTemplatesStore();
  const { templatesList } = storeToRefs(templatesStore);

  const { showSuccess } = useNotificationsStore();
  const { getQueryUserId, assignUserId } = useSuperAccount();
  const { me, enableTemplates } = useUserAccess();

  const notifications = ref<UserNotificationsI>(getDefaultNotifications());

  const {
    hasChanges,
    updateCopy,
  } = useHasChanges(notifications);

  function updateTemplatesResponsibleInfo(email: string, enable: boolean) {
    templatesList.value.forEach((template: TemplateI) => {
      if (template.responsible && template.responsible?.id === (id ? id : me.value?.id)) {
        template.responsible.emailForNotification = email
        template.responsible.enableNotifications = enable
      }
    })
  }

  function getNotifications() {
    isFetching.value = true;

    useApi().settings.users.fetchNotificationsSettings<UserNotificationsI>(getQueryUserId(id))
      .then((data) => {
        notifications.value.emailAccount = {
          email: data?.emailAccount?.email || '',
          enableNotifications: data?.emailAccount?.enableNotifications || false,
          autoCreateTemplateNotification: data?.emailAccount?.autoCreateTemplateNotification || false,
        }

        const smsSettings = data?.phoneAccount?.settings;

        notifications.value.phoneAccount.settings = {
          application: smsSettings?.application?.length ? smsSettings.application : getDefaultApplication(),
          auction: smsSettings?.auction?.length ? smsSettings.auction : getDefaultAuction(),
          contract: smsSettings?.contract?.length ? smsSettings.contract : getDefaultContract(),
        }

        error.value = ''
        updateCopy()
      })
      .catch(() => error.value = 'Произошла ошибка при получении данных, попробуйте позднее')
      .finally(() => isFetching.value = false);
  }

  function updateNotifications(hide?: Function) {
    isSaving.value = true;

    useApi().settings.users.patchNotificationsSettings(assignUserId(id, notifications.value))
      .then(() => {
        error.value = ''
        showSuccess()
        updateCopy()
        if (hide) hide()
        if (enableTemplates.value) updateTemplatesResponsibleInfo(notifications.value.emailAccount.email, notifications.value.emailAccount.enableNotifications)
      })
      .catch(() => error.value = 'Произошла ошибка при сохранении данных, попробуйте позднее')
      .finally(() => isSaving.value = false);
  }

  return {
    error,
    notifications,
    isSaving,
    isFetching,
    hasChanges,
    getNotifications,
    updateNotifications,
  }
}
