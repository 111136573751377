<template lang="pug">
.notification-block
  .header Рассылка по событиям
  .content(:class="fullInfo && '_full'")
    .settings-block(v-for="el in elements" :class="fullInfo && '_full'")
      .label(:class="fullInfo && '_full'") {{ el.title }}
      .block-content(:class="fullInfo && '_full'")
        .inner-block(v-for="(sett, i) of el.settings")
          .inner-block-row
            ui-checkbox(
              v-model="modelValue[el.label][i].isEnabled"
              :id="el.label + i"
              :label="sett.primary"
              :message="fullInfo ? sett.secondary : ''"
            )
            icon-button.sms-settings-icon(
              :icon="UiIconNames.Icon_Cog"
              :size="20"
              @click="openSmsNumberDialog(sett.type, el.label + ':' + i)"
            )

edit-time-notification-dialog(
  :data="dialogData"
  v-model:show="showTimeDialog"
  @update:time="updateItemTime"
)
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, ref, computed } from "vue";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { getToday } from "@/utils/formatter/dateFormatter";
import type { PropType } from "vue";
import type { PhoneSettingsI } from "@/stores/auth/UserInterface";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import { useVModel } from "@vueuse/core";
import UiIconNames from "@/components/ui/icon/UiIconNames";

type DialogEmitterType = 'application:0'|'application:1'|'auction:0'|'auction:1'|'contract:0'|'contract:1'

enum Direction {
  'application:0' = 'time',
  'application:1' = 'hours',
  'auction:0' = 'hours',
  'auction:1' = 'minutes',
  'contract:0' = 'time',
  'contract:1' = 'time',
}

export default defineComponent({
  name: "SmsNotificationBlock",
  components: {
    UiCheckbox,
    IconButton,
    EditTimeNotificationDialog: defineAsyncComponent(() => import("@/components/pages/settings/notificationsPage/dialogs/EditTimeNotificationDialog.vue")),
  },
  emits: [
    "update:modelValue",
    "update:time",
  ],
  props: {
    fullInfo: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object as PropType<PhoneSettingsI>,
      default: () => ({}),
    },
  },
  setup(props, context) {

    const dialogData = ref();
    const showTimeDialog = ref(false);

    const modelValue = useVModel(props, 'modelValue', context.emit)

    const today = getToday();

    const elements = computed(() => [
      {
        title: 'Подача заявки',
        label: 'application',
        settings: [{
          type: 'time',
          until: `предыдущего рабочего дня, предшествующего дню срока истечения подачи заявки`,
          primary: `В ${ props.modelValue.application[0].time } предыдущего рабочего дня, предшествующего дню срока истечения подачи заявки`,
          secondary: `Подача заявки 3456765432345 до ${ today }`,
        }, {
          type: 'hours',
          until: `до истечения срока подачи заявки`,
          primary: `За ${ props.modelValue.application[1].hours } ч. до истечения срока подачи заявки`,
          secondary: `Подача заявки 3456765432345 через ${ props.modelValue.application[1].hours } ч. в 12:00 ${ today }`,
        }],
      },
      {
        title: 'Торги',
        label: 'auction',
        settings: [{
          type: 'hours',
          until: `до начала аукциона`,
          primary: `За ${ props.modelValue.auction[0].hours } ч. до начала аукциона`,
          secondary: `Торги 45675434567 через ${ props.modelValue.auction[0].hours } ч.`,
        }, {
          type: 'minutes',
          until: `до начала аукциона`,
          primary: `За ${ props.modelValue.auction[1].minutes } мин. до начала аукциона`,
          secondary: `Торги 45675434567 через ${ props.modelValue.auction[1].minutes } мин.`,
        }],
      },
      {
        title: 'Контракты',
        label: 'contract',
        settings: [{
          type: 'time',
          until: `предыдущего рабочего дня перед днем истечения подписания контракта`,
          primary: `В ${ props.modelValue.contract[0].time } предыдущего рабочего перед днем истечения подписания контракта`,
          secondary: `Подписание контракта 34566543456 до ${ today }`,
        }, {
          type: 'time',
          until: `даты истечения подписания контракта`,
          primary: `В ${ props.modelValue.contract[1].time } даты истечения подписания контракта`,
          secondary: `${ today } истекает подписание контракта 34566543456`,
        }],
      }
    ])

    const emitter = ref<DialogEmitterType>('application:0')

    function openSmsNumberDialog(type: "time"|"hours"|"minutes", emitterLocal: DialogEmitterType) {
      emitter.value = emitterLocal
      const emitterLocalArr: ['application'|'auction'|'contract', 0|1] = emitter.value.split(':') as ['application'|'auction'|'contract', 0|1]
      dialogData.value = {
        type: type,
        value: props.modelValue[emitterLocalArr[0]][emitterLocalArr[1]][Direction[emitter.value]],
        text: elements.value.find(e => e.label === emitterLocalArr[0])?.settings[emitterLocalArr[1]].until,
      }
      showTimeDialog.value = true;
    }

    function updateItemTime(time: string|number) {
      const emitterLocalArr: ['application'|'auction'|'contract', 0|1] = emitter.value.split(':') as ['application'|'auction'|'contract', 0|1]
      modelValue.value[emitterLocalArr[0]][emitterLocalArr[1]][Direction[emitter.value]] = time
    }

    return {
      elements,
      showTimeDialog,
      dialogData,
      openSmsNumberDialog,
      updateItemTime,
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.notification-block {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .header {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    gap: 16px;

    &._full {
      display: grid;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
      grid-column-gap: 32px;
    }
  }

  .settings-block {
    width: 254px;
    flex-flow: column;
    display: flex;
    place-self: stretch;

    &._full {
      width: auto;
    }

    .label {
      @include label-13-16;
      font-weight: 500;
      padding: 8px 0;

      &._full {
        font-size: 14px;
      }
    }

    .block-content {
      border: 1px solid var(--input-border-color);
      box-sizing: border-box;
      border-radius: 4px;
      height: 100%;
      padding: 16px;
      display: flex;
      flex-flow: column;
      gap: 12px;

      &._full {
        padding: 16px 12px;
        gap: 16px;
      }

      .inner-block {
        display: flex;
        flex-flow: column;
        height: 50%;

        .inner-block-row {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }
      }
    }
  }
}

:deep(.sms-settings-icon) {
  align-items: flex-start;
}

@media (max-width: 1140px) {
  .notification-block {
    .content._full {
      grid-column-gap: 16px;
    }
  }
}

@media (max-width: 820px) {
  .notification-block {
    .content._full {
      display: flex;
      flex-flow: column;
    }

    .settings-block {
      .block-content._full {
        gap: 8px;
      }
    }
  }
}
</style>
